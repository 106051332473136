import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button, Form, FormInput } from 'shards-react';
import PasswordAndCommentModal, { useToggleModal } from '../../../components/common/PasswordAndCommentModal';
import RequirePermissions from '../../../components/RequirePermissions';
import API from '../../../data/API';

const formatFromCents = (value, placeholder = '$0.00') => {
	value = value / 100;
	return value ? '$' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : placeholder;
};

const formatToCents = value => {
	value = value.replace(/[^0-9.]/g, '');
	return value ? value * 100 : 0;
};

const FeaturesTabDisplay = ({ businessProfileId }) => {
	const [open, toggle] = useToggleModal();

	const [loading, setLoading] = useState(false);
	const [invoiceFeatures, setInvoiceFeatures] = useState(null);
	const [tipFeatures, setTipFeatures] = useState(null);
	const [insightsFeatures, setInsightsFeatures] = useState(null);

	const [action, setAction] = useState('');

	const onInvoiceFeaturesChange = (field, value) => {
		setInvoiceFeatures({ ...invoiceFeatures, [field]: value });
	};

	const onTipFeaturesChange = (field, value) => {
		setTipFeatures({ ...tipFeatures, [field]: value });
	};

	const formatInvoicesForDisplay = invoices => {
		return {
			...invoices,
			invoiceLimitInstance: formatFromCents(invoices.invoiceLimitInstance),
			invoiceLimitDay30: formatFromCents(invoices.invoiceLimitDay30),
			invoiceAverageInstance: formatFromCents(invoices.invoiceAverageInstance, '---'),
			invoiceLifetimeAverage: formatFromCents(invoices.invoiceLifetimeAverage, '---'),
			invoiceDay30Average: formatFromCents(invoices.invoiceDay30Average, '---'),
			invoiceDay30Volume: formatFromCents(invoices.invoiceDay30Volume, '---'),
			invoiceLifetimeVolume: formatFromCents(invoices.invoiceLifetimeVolume, '---'),
		};
	};

	const formatTipsForDisplay = tips => {
		return {
			...tips,
			tipLimitInstance: formatFromCents(tips.tipLimitInstance),
			tipLimitDay30: formatFromCents(tips.tipLimitDay30),
			tipAverageInstance: formatFromCents(tips.tipAverageInstance, '---'),
			tipLifetimeAverage: formatFromCents(tips.tipLifetimeAverage, '---'),
			tipDay30Average: formatFromCents(tips.tipDay30Average, '---'),
			tipDay30Volume: formatFromCents(tips.tipDay30Volume, '---'),
			tipLifetimeVolume: formatFromCents(tips.tipLifetimeVolume, '---'),
		};
	};

	const formatInvoicesForApi = invoices => {
		return {
			...invoices,
			invoiceLimitInstance: formatToCents(invoices.invoiceLimitInstance),
			invoiceLimitDay30: formatToCents(invoices.invoiceLimitDay30),
		};
	};

	const getFeatures = async () => {
		setLoading(true);
		const { invoices, tips, insights } = await API.get(`/banking-admin/features/${businessProfileId}`);
		setInvoiceFeatures(formatInvoicesForDisplay(invoices));
		setTipFeatures(formatTipsForDisplay(tips));
		setInsightsFeatures(insights);
		setLoading(false);
	};

	// invoices
	const enableInvoiceFeatures = async () => {
		setLoading(true);
		try {
			const { invoices } = await API.post(`/banking-admin/features/${businessProfileId}/invoices/enable`);
			setInvoiceFeatures(formatInvoicesForDisplay(invoices));
		} finally {
			setLoading(false);
		}
	};

	const toggleInvoiceFeaturesOff = async () => {
		setLoading(true);
		try {
			const { invoices } = await API.post(`/banking-admin/features/${businessProfileId}/invoices/toggle-off`);
			setInvoiceFeatures(formatInvoicesForDisplay(invoices));
		} finally {
			setLoading(false);
		}
	};

	const deactivateInvoiceFeatures = async (password, comment) => {
		setLoading(true);
		try {
			const { invoices } = await API.post(`/banking-admin/features/${businessProfileId}/invoices/deactivate`, {
				password,
				comment,
			});
			setInvoiceFeatures(formatInvoicesForDisplay(invoices));
		} finally {
			setLoading(false);
		}
	};

	const activateInvoiceFeatureWithValues = async (password, comment) => {
		setLoading(true);
		try {
			const { invoices } = await API.post(`/banking-admin/features/${businessProfileId}/invoices/activate`, {
				password,
				invoices: formatInvoicesForApi(invoiceFeatures),
				comment,
			});
			setInvoiceFeatures(formatInvoicesForDisplay(invoices));
		} finally {
			setLoading(false);
		}
	};

	const updateInvoiceFeatureWithValue = async () => {
		setLoading(true);
		try {
			const { invoices } = await API.post(`/banking-admin/features/${businessProfileId}/invoices/update`, {
				invoices: formatInvoicesForApi(invoiceFeatures),
			});
			setInvoiceFeatures(formatInvoicesForDisplay(invoices));
		} finally {
			setLoading(false);
		}
	};

	// Tips
	const enableTipFeatures = async () => {
		setLoading(true);
		try {
			const { tips } = await API.post(`/banking-admin/features/${businessProfileId}/tips/enable`);
			setTipFeatures(formatTipsForDisplay(tips));
		} finally {
			setLoading(false);
		}
	};

	const enableInsightsFeatures = async () => {
		setLoading(true);
		try {
			const { insights } = await API.post(`/banking-admin/features/${businessProfileId}/insights/enable`);
			setInsightsFeatures(insights);
		} finally {
			setLoading(false);
		}
	};

	const toggleInsightsFeaturesOff = async () => {
		setLoading(true);
		try {
			const { insights } = await API.post(`/banking-admin/features/${businessProfileId}/insights/toggle-off`);
			setInsightsFeatures(insights);
		} finally {
			setLoading(false);
		}
	};

	const toggleTipFeaturesOff = async () => {
		setLoading(true);
		try {
			const { tips } = await API.post(`/banking-admin/features/${businessProfileId}/tips/toggle-off`);
			setTipFeatures(formatTipsForDisplay(tips));
		} finally {
			setLoading(false);
		}
	};

	const deactivateTipFeatures = async (password, comment) => {
		setLoading(true);
		try {
			const { tips } = await API.post(`/banking-admin/features/${businessProfileId}/tips/deactivate`, {
				password,
				comment,
			});
			setTipFeatures(formatTipsForDisplay(tips));
		} finally {
			setLoading(false);
		}
	};

	const activateTipFeatureWithValues = async (password, comment) => {
		setLoading(true);
		try {
			const { tips } = await API.post(`/banking-admin/features/${businessProfileId}/tips/activate`, {
				password,
				tips: tipFeatures,
				comment,
			});
			setTipFeatures(formatTipsForDisplay(tips));
		} finally {
			setLoading(false);
		}
	};

	const activateLiteTipFeatures = async (password, comment) => {
		setLoading(true);
		try {
			const { tips } = await API.post(`/banking-admin/features/${businessProfileId}/tips/activate-lite`, {
				password,
				tips: tipFeatures,
				comment,
			});
			setTipFeatures(formatTipsForDisplay(tips));
		} finally {
			setLoading(false);
		}
	};

	const updateTipFeatureWithValue = async () => {
		setLoading(true);
		try {
			const { tips } = await API.post(`/banking-admin/features/${businessProfileId}/tips/update`, {
				tips: tipFeatures,
			});
			setTipFeatures(formatTipsForDisplay(tips));
		} finally {
			setLoading(false);
		}
	};

	const toggleActionModal = action => {
		setAction(action);
		toggle();
	};

	const submitAction = async (password, comment) => {
		switch (action) {
			// invoice features
			case 'deactivateInvoiceFeatures':
				await deactivateInvoiceFeatures();
				break;
			case 'activateInvoiceFeatureWithValues':
				await activateInvoiceFeatureWithValues(password, comment);
				break;
			// tip features
			case 'deactivateTipFeatures':
				await deactivateTipFeatures(password, comment);
				break;
			case 'activateTipFeatureWithValues':
				await activateTipFeatureWithValues(password, comment);
				break;
			case 'activateLiteTipFeatures':
				await activateLiteTipFeatures(password, comment);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		getFeatures();
	}, [businessProfileId]);

	const displayStatus = status => {
		return {
			inactive: 'Inactive',
			requested: 'Access Requested',
			active: 'Active',
			disabled: 'Disabled',
			lite: 'Lite',
		}[status];
	};

	return (
		<>
			<PasswordAndCommentModal actionLabel={action} onConfirm={submitAction} toggle={open} />

			{invoiceFeatures && (
				<Row className="py-4 d-flex justify-content-between">
					<Col xs="12">
						<Card small className="mb-3">
							<CardHeader className="border-bottom">
								<h6 className="m-0">Invoice: {displayStatus(invoiceFeatures.status)}</h6>
							</CardHeader>
							<CardBody className="m-2">
								<RequirePermissions permissions="business.write" showRequiredPermissions>
									<div>
										{invoiceFeatures.status === 'inactive' &&
											(invoiceFeatures.enabled ? (
												<Button
													style={{
														marginRight: 10,
													}}
													disabled={loading}
													theme="danger"
													onClick={toggleInvoiceFeaturesOff}>
													Hide Invoices In App
												</Button>
											) : (
												<Button
													style={{
														marginRight: 10,
													}}
													disabled={loading}
													theme="success"
													onClick={enableInvoiceFeatures}>
													Offer Invoices In App
												</Button>
											))}

										{invoiceFeatures.status === 'requested' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={() => toggleActionModal('activateInvoiceFeatureWithValues')}>
												Activate With Values Below
											</Button>
										)}

										{invoiceFeatures.status === 'active' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={updateInvoiceFeatureWithValue}>
												Update With Values Below
											</Button>
										)}

										{invoiceFeatures.status === 'disabled' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={() => toggleActionModal('activateInvoiceFeatureWithValues')}>
												Activate With Values Below
											</Button>
										)}

										{invoiceFeatures.status === 'requested' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="danger"
												onClick={() => toggleActionModal('deactivateInvoiceFeatures')}>
												Decline Access
											</Button>
										)}

										{invoiceFeatures.status === 'active' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="danger"
												onClick={() => toggleActionModal('deactivateInvoiceFeatures')}>
												Deactivate
											</Button>
										)}
									</div>
								</RequirePermissions>

								{invoiceFeatures.status !== 'inactive' && (
									<RequirePermissions permissions="business.read" showRequiredPermissions>
										<div
											style={{
												marginTop: 40,
											}}>
											<Form>
												<Row
													style={{
														marginBottom: 5,
													}}>
													<Col md="6">
														<span className="text-semibold text-fiord-blue">
															Instance Limit ($)
														</span>
														<FormInput
															size="md"
															className="mb-3"
															placeholder="$xxxx.xx"
															disabled={loading}
															value={invoiceFeatures.invoiceLimitInstance}
															onChange={event =>
																onInvoiceFeaturesChange(
																	'invoiceLimitInstance',
																	event.target.value,
																)
															}
															required
														/>
													</Col>
													<Col md="6">
														<span className="text-semibold text-fiord-blue">
															30-Day Limit ($)
														</span>
														<FormInput
															size="md"
															className="mb-3"
															placeholder="$xxxx.xx"
															disabled={loading}
															value={invoiceFeatures.invoiceLimitDay30}
															onChange={event =>
																onInvoiceFeaturesChange(
																	'invoiceLimitDay30',
																	event.target.value,
																)
															}
															required
														/>
													</Col>
												</Row>
												<Row
													style={{
														marginBottom: 2,
													}}>
													<Col md="6">
														Self-Reported Average: {invoiceFeatures.invoiceAverageInstance}
													</Col>
													<Col md="6">
														30-Day Volume: {invoiceFeatures.invoiceDay30Volume} (
														{invoiceFeatures.invoiceDay30Count})
													</Col>
												</Row>
												<Row
													style={{
														marginBottom: 2,
													}}>
													<Col md="6">
														30-Day Average: {invoiceFeatures.invoiceDay30Average} (
														{invoiceFeatures.invoiceDay30Count})
													</Col>
													<Col md="6">
														Lifetime Volume: {invoiceFeatures.invoiceLifetimeVolume} (
														{invoiceFeatures.invoiceLifetimeCount})
													</Col>
												</Row>
												<Row
													style={{
														marginBottom: 40,
													}}>
													<Col md="6">
														Lifetime Average: {invoiceFeatures.invoiceLifetimeAverage} (
														{invoiceFeatures.invoiceLifetimeCount})
													</Col>
												</Row>
												<Row>
													<Col md="6">
														<span className="text-semibold text-fiord-blue">
															Daily Invoice Count Limit
														</span>
														<FormInput
															size="md"
															className="mb-3"
															disabled={loading}
															value={invoiceFeatures.invoiceCountLimitDay1}
															onChange={event =>
																onInvoiceFeaturesChange(
																	'invoiceCountLimitDay1',
																	event.target.value,
																)
															}
															required
														/>
													</Col>
													<Col md="6">
														<span className="text-semibold text-fiord-blue">
															Hold Days Before Payout
														</span>
														<FormInput
															size="md"
															className="mb-3"
															disabled={loading}
															value={invoiceFeatures.invoiceHoldDays}
															onChange={event =>
																onInvoiceFeaturesChange(
																	'invoiceHoldDays',
																	event.target.value,
																)
															}
															required
														/>
													</Col>
												</Row>
											</Form>
										</div>
									</RequirePermissions>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}

			{tipFeatures && (
				<Row className="py-4 d-flex justify-content-between">
					<Col xs="12">
						<Card small className="mb-3">
							<CardHeader className="border-bottom">
								<h6 className="m-0">Tips: {displayStatus(tipFeatures.status)}</h6>
							</CardHeader>

							<CardBody className="m-2">
								<div>
									<RequirePermissions permissions="business.write" showRequiredPermissions>
										{tipFeatures.status === 'inactive' &&
											(tipFeatures.enabled ? (
												<Button
													style={{
														marginRight: 10,
													}}
													disabled={loading}
													theme="danger"
													onClick={toggleTipFeaturesOff}>
													Hide Tips In App
												</Button>
											) : (
												<Button
													style={{
														marginRight: 10,
													}}
													disabled={loading}
													theme="success"
													onClick={enableTipFeatures}>
													Offer Tips In App
												</Button>
											))}

										{tipFeatures.status === 'requested' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={() => toggleActionModal('activateTipFeatureWithValues')}>
												Activate With Values Below
											</Button>
										)}

										{tipFeatures.status === 'lite' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={() => toggleActionModal('activateTipFeatureWithValues')}>
												Upgrade With Values Below
											</Button>
										)}

										{tipFeatures.status === 'active' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={updateTipFeatureWithValue}>
												Update With Values Below
											</Button>
										)}

										{tipFeatures.status === 'disabled' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={() => toggleActionModal('activateLiteTipFeatures')}>
												Activate Lite Tips
											</Button>
										)}

										{tipFeatures.status === 'requested' && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="danger"
												onClick={() => toggleActionModal('deactivateTipFeatures')}>
												Decline Access
											</Button>
										)}

										{(tipFeatures.status === 'active' || tipFeatures.status === 'lite') && (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="danger"
												onClick={() => toggleActionModal('deactivateTipFeatures')}>
												Deactivate Tips
											</Button>
										)}
									</RequirePermissions>
									{tipFeatures.status !== 'inactive' && tipFeatures.status !== 'disabled' && (
										<RequirePermissions permissions="business.read" showRequiredPermissions>
											<div
												style={{
													marginTop: 40,
												}}>
												<Form>
													<Row
														style={{
															marginBottom: 2,
														}}>
														<Col md="6">
															30-Day Average: {tipFeatures.tipDay30Average} (
															{tipFeatures.tipDay30Count})
														</Col>
														<Col md="6">
															30-Day Volume: {tipFeatures.tipDay30Volume} (
															{tipFeatures.tipDay30Count})
														</Col>
													</Row>
													<Row
														style={{
															marginBottom: 40,
														}}>
														<Col md="6">
															Lifetime Average: {tipFeatures.tipLifetimeAverage} (
															{tipFeatures.tipLifetimeCount})
														</Col>
														<Col md="6">
															Lifetime Volume: {tipFeatures.tipLifetimeVolume} (
															{tipFeatures.tipLifetimeCount})
														</Col>
													</Row>
													<Row
														style={{
															marginBottom: 5,
														}}>
														<Col md="6">
															<span className="text-semibold text-fiord-blue">
																Hold Days Before Payout
															</span>
															<FormInput
																size="md"
																className="mb-3"
																disabled={loading}
																value={tipFeatures.tipHoldDays}
																onChange={event =>
																	onTipFeaturesChange(
																		'tipHoldDays',
																		event.target.value,
																	)
																}
																required
															/>
														</Col>
													</Row>
												</Form>
											</div>
										</RequirePermissions>
									)}
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}

			{insightsFeatures && (
				<Row className="py-4 d-flex justify-content-between">
					<Col xs="12">
						<Card small className="mb-3">
							<CardHeader className="border-bottom">
								<h6 className="m-0">Insights: {displayStatus(insightsFeatures.status)}</h6>
							</CardHeader>

							<CardBody className="m-2">
								<div>
									<RequirePermissions permissions="business.write" showRequiredPermissions>
										{insightsFeatures?.enabled ? (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="danger"
												onClick={toggleInsightsFeaturesOff}>
												Hide Insights In App
											</Button>
										) : (
											<Button
												style={{
													marginRight: 10,
												}}
												disabled={loading}
												theme="success"
												onClick={enableInsightsFeatures}>
												Offer Insights In App
											</Button>
										)}
									</RequirePermissions>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
		</>
	);
};
export default FeaturesTabDisplay;
